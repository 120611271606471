export default class UserLocationManager {
    constructor(onUpdateGroupsDistance) {
        this.mapId = '#map-location';
        this.userLocation = null;
        this.map = null;
        this.marker = null;
        this.latitude = 40.415363;
        this.longitude = -3.707398;
        this.isStatic = true;
        this.onUpdateGroupsDistance = onUpdateGroupsDistance;
        this.eventDelegation();
        if (myApp.browserStorageService.getItem('latitude') && myApp.browserStorageService.getItem('longitude')) {
            this.latitude = myApp.browserStorageService.getItem('latitude');
            this.longitude = myApp.browserStorageService.getItem('longitude');
            this.isStatic = false;
            this.onUpdateGroupsDistance(this.getUserLocation());
        }
    }

    eventDelegation() {
        document.querySelectorAll('[data-action="show-user-location-map"]').forEach((element) => {
            element.addEventListener('click', () => {
                this.initialize();
            });
        });
    }

    initialize() {
        if (this.map) {
            return;
        }
        this.latLng = {lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)};
        this._createMap();
        this._createMarker();
    }

    getUserLocation() {
        return {
            latitude: this.latitude,
            longitude: this.longitude
        };
    }

    async _createMap() {
        const {Map} = await google.maps.importLibrary("maps");

        this.map = new Map(document.getElementById('map-location'), {
            center: this.latLng,
            zoom: this.isStatic ? 4 : 13,
            mapId: 'map-location',
            libraries: ["geometry", "marker"],
            gestureHandling: 'greedy'
        });

        this.map.addListener("click", (e) => {
            this._setMarkerPosition(e.latLng);
        });
    }

    _setMarkerPosition(latLng) {
        this.latLng = latLng;
        this.marker.position = latLng;
        this.latitude = latLng.lat();
        this.longitude = latLng.lng();
        this.setBrowserStorageLatLng(this.latitude, this.longitude);
        this.onUpdateGroupsDistance(this.getUserLocation());
    }

    async _createMarker() {
        const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");
        let url = myApp.config.cdnPath.img + '/maps/marker-no-selected.svg';
        const marker = document.createElement("div");
        marker.classList.add('filter-marker');

        this.marker = new AdvancedMarkerElement({
            position: this.latLng,
            map: this.map,
            content: marker
        });
    }

    setBrowserStorageLatLng(latitude, longitude) {
        myApp.browserStorageService.setItem('latitude', latitude);
        myApp.browserStorageService.setItem('longitude', longitude);
    }
}