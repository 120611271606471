import CriteriaType from "./criteriaType";
import Criteria from "./criteria";

export default class CriteriaList {
    constructor() {
        this._criterias = [];
    }

    initializeCriteria(element) {
        let filterType = element.dataset.filterType;
        let valueA = element.dataset.valuea;
        let valueB = element.dataset.valueb;
        let text = element.closest('[data-hide="checkbox"]').querySelector('[ data-filter-text]').innerHTML;
        let criteria = null;
        switch (filterType) {
            case CriteriaList.typePrice:
                criteria = new Criteria(CriteriaList.typePrice, new CriteriaType(CriteriaType.typeEqual), valueA, valueB);
                break;
            case CriteriaList.typeAccommodationCategory:
                criteria = new Criteria(CriteriaList.typeAccommodationCategory, new CriteriaType(CriteriaType.typeEqual), valueA, valueB);
                break
            case CriteriaList.typeRate:
                criteria = new Criteria(CriteriaList.typeRate, new CriteriaType(CriteriaType.typeEqual), valueA, valueB);
                break;
            case CriteriaList.typeAllowPets:
                criteria = new Criteria(CriteriaList.typeAllowPets, new CriteriaType(CriteriaType.typeEqual), valueA, valueB);
                break;
            case CriteriaList.typeFreeCancellation:
                criteria = new Criteria(CriteriaList.typeFreeCancellation, new CriteriaType(CriteriaType.typeEqual), valueA, valueB);
                break;
            case CriteriaList.typeMealPlan:
                criteria = new Criteria(CriteriaList.typeMealPlan, new CriteriaType(CriteriaType.typeEqual), valueA, valueB);
                break;
            case CriteriaList.typeFeaturedDates:
                criteria = new Criteria(CriteriaList.typeFeaturedDates, new CriteriaType(CriteriaType.typeEqual), valueA, valueB);
                break;
            case CriteriaList.typeThematic:
                criteria = new Criteria(CriteriaList.typeThematic, new CriteriaType(CriteriaType.typeEqual), valueA, valueB);
                break;
            case CriteriaList.typeLocation:
                criteria = new Criteria(CriteriaList.typeLocation, new CriteriaType(CriteriaType.typeEqual), valueA, valueB);
                break;
        }
        if (criteria) {
            criteria.text = text;
            this._criterias.push(criteria);
        }

        return criteria;
    }

    setCriteria(filterType, valueA, valueB, checked) {
        for (let criteria of this._criterias) {
            if (criteria.checkCriteria(filterType, valueA, valueB)) {
                criteria.checked = checked;
                return;
            }
        }
    }

    cleanCriteria() {
        for (let criteria of this._criterias) {
            criteria.checked = false;
        }
    }

    hasCriteriaType(instance) {
        for (let criteria of this._criterias) {
            if (criteria instanceof instance) {
                return true;
            }
        }
        return false;
    }

    hasVisibleCriteriaType(name) {
        for (let criteria of this._criterias) {
            if (criteria.name == name && criteria.visible) {
                return true;
            }
        }
        return false;
    }

    hasItemsCriteriaType(name) {
        for (let criteria of this._criterias) {
            if (criteria.name == name && criteria.visible && criteria.numItems > 0) {
                return true;
            }
        }
        return false;
    }

    getCriteria(filterName, valueA, valueB = null) {
        for (let criteria of this._criterias) {
            if (filterName == criteria.name && valueA == criteria.valueA && valueB == criteria.valueB) {
                return criteria;
            }
        }
    }

    getCriteriaChecked(name = null) {
        let criteriaChecked = [];

        for (let criteria of this._criterias) {
            if (criteria.checked && (!name || name && name == criteria.name)) {
                criteriaChecked.push(criteria);
            }
        }
        return criteriaChecked;
    }

    hasCriteriaChecked(name = null) {
        for (let criteria of this._criterias) {
            if (criteria.checked && (!name || name && name == criteria.name)) {
                return true;
            }
        }
        return false;
    }

    getCriteriaByName(name) {
        let criterias = [];

        for (let criteria of this._criterias) {
            if (name == criteria.name) {
                criterias.push(criteria);
            }
        }

        return criterias;
    }



    get criterias() {
        return this._criterias;
    }

    add(criteria) {
        this._criterias.push(criteria);
    }

    addAll(criteriaArray) {
        this._criterias = [...this._criterias, ...criteriaArray];
    }
}

CriteriaList.typePrice = 'price';
CriteriaList.typeAccommodationCategory = 'accommodationcategory';
CriteriaList.typeRate = 'rating';
CriteriaList.typeFreeCancellation = 'freecancellation';
CriteriaList.typeAllowPets = 'allowpets';
CriteriaList.typeMealPlan = 'mealplan';
CriteriaList.typeFeaturedDates = 'featureddates';
CriteriaList.typeThematic = 'thematic';
CriteriaList.typeLocation = 'location';

CriteriaList.criteriaTypes = [
    CriteriaList.typePrice,
    CriteriaList.typeAccommodationCategory,
    CriteriaList.typeRate,
    CriteriaList.typeFreeCancellation,
    CriteriaList.typeAllowPets,
    CriteriaList.typeMealPlan,
    CriteriaList.typeFeaturedDates,
    CriteriaList.typeThematic,
    CriteriaList.typeLocation
];