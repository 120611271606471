import GroupList from "./groupList";
import GroupListFilter from "./groupListFilter/groupListFilter";
import GroupListPrinter from "./grupListPrinter";
import GroupListSorter from "./groupListSorter";

export default class GroupListManager {
    constructor() {
        if (!myApp.newFilters) {
            return
        }
        let groupElements = document.querySelectorAll('[data-entity="group"]');

        this.groupList = new GroupList(groupElements);
        this.groupListFilter = new GroupListFilter(this.printGroups.bind(this));
        this.groupListSorter = new GroupListSorter(this.printGroups.bind(this), this.updateGroupsDistance.bind(this));
        this.groupListPrinter = new GroupListPrinter(groupElements);
        let printGroupsOnFilter = this.groupListFilter.initializeFilters();
        let printGroupsOnSort = this.groupListSorter.initializeSorter();
        if (printGroupsOnFilter || printGroupsOnSort) {
            this.printGroups();
        }
    }

    updateGroupsDistance(userLocation) {
        this.groupList.getList().forEach((group) => {
            group.calculateDistance(userLocation);
        });
    }

    printGroups() {
        const start = Date.now();
        let groups = this.groupListFilter.filter(this.groupList.getList());
        groups = this.groupListSorter.sort(groups);
        if (this.groupListSorter.selectedSortMethod === GroupListSorter.METHOD_DISTANCE) {
            this.groupListPrinter.updateGroupsDistance(this.groupList.getList());
        }
        this.groupListPrinter.printGroups(groups.map(group => group.id));
        this.groupListFilter.updateFilterView(groups, this.groupList.getList());
        this.groupListSorter.updateSorterView();
        const end = Date.now();
        let executionTimeElement = document.querySelector('[data-execution-time]');
        executionTimeElement.innerHTML = `Execution time: ${end - start} ms`;
        executionTimeElement.style.display = '';
    }
}