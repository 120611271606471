import CriteriaList from "./criteria/criteriaList";
import GroupCriteriaTypeFilter from "./criteria/groupCriteriaTypeFilter";
import FilterPrinter from "./filterPrinter";
import Criteria from "./criteria/criteria";

export default class GroupListFilter {
    constructor(onFilterFunction) {
        this._criteriaList = new CriteriaList();
        this.groupCriteriaTypeFilter = new GroupCriteriaTypeFilter();
        this.filterPrinter = new FilterPrinter(this._criteriaList, onFilterFunction);
        this.onFilterFunction = onFilterFunction;
    }

    initializeFilters() {
        document.querySelectorAll('[data-action="filter"]').forEach((element) => {
            let criteria = this._criteriaList.initializeCriteria(element);
            if (!criteria) {
                return;
            }
            element.addEventListener('change', (currentElement) => {
                this._onFilter(criteria, currentElement);
            });
        });
        return this.filterPrinter.initializeFilters();
    }

    filter(groups) {
        if (this._criteriaList.getCriteriaChecked().length === 0) {
            return groups;
        }
        return groups.filter(group => this._isFiltered(group));
    }

    _onFilter(criteria, currentElement) {
        this._criteriaList.setCriteria(criteria.name, criteria.valueA, criteria.valueB, currentElement.currentTarget.checked);
        this.onFilterFunction();
    }

    updateFilterView(filteredGroups, groups) {
        let numGroups;
        for (let criteria of this._criteriaList.criterias) {
            this._setNumGroupsFiltered(criteria, groups);
            this.filterPrinter.setCriteriaVisibility(criteria);
        }

        this.filterPrinter.updateFilterView(filteredGroups, groups);
    }

    _setNumGroupsFiltered(criteria, groups) {
        this._deactivateAllCategoryCriteria(criteria);
        let numGroups = 0;

        for (let group of groups) {
            if (!this.groupCriteriaTypeFilter._checkCriteria(group, criteria)) {
                continue;
            }
            if (this._isFiltered(group)) {
                numGroups++;
            }
        }
        this._resetCategoryCriteria(criteria);
        criteria.numItems = (numGroups > 0 || criteria.checked) ? numGroups : 0;

    }

    _deactivateAllCategoryCriteria(criteria) {
        for (let criteriaTmp of this._criteriaList.getCriteriaByName(criteria.name)) {
            if (criteriaTmp.checked) {
                criteriaTmp.checkedTmp = true;
            } else {
                criteriaTmp.checkedTmp = false;
            }

            if (criteriaTmp === criteria) {
                criteriaTmp.checked = true;
            } else {
                if (criteriaTmp.operationType === Criteria.typeOr) {
                    criteriaTmp.checked = false;
                }
            }
        }
    }

    _resetCategoryCriteria(criteria) {
        for (let criteriaTmp of this._criteriaList.getCriteriaByName(criteria.name)) {
            criteriaTmp.checked = criteriaTmp.checkedTmp;
        }
    }

    _isFilteredCounter(group, criteria) {
        let checkGroupCriteriaTmp, criteriaCheckedTmp;
        let criteriaChecked = this._criteriaList.getCriteriaChecked();

        for (let criteriaTmp of criteriaChecked) {

            checkGroupCriteriaTmp = this.groupCriteriaTypeFilter._checkCriteria(group, criteriaTmp)
            if (!checkGroupCriteriaTmp && criteriaTmp.name != criteria.name) {
                criteriaCheckedTmp = this._criteriaList.getCriteriaChecked(criteriaTmp.name);

                for (let criteriaTmp1 of criteriaCheckedTmp) {
                    if (this.groupCriteriaTypeFilter._checkCriteria(group, criteriaTmp1)) {
                        return true;
                    }
                }
                return false;
            }
        }
        return true;
    }

    _isFiltered(group) {
        let criteriaActive = this._criteriaList.getCriteriaChecked();
        let isFiltered = true;
        for (let criteria of criteriaActive) {
            if (!this.groupCriteriaTypeFilter.isFiltered(this._criteriaList, criteria, group)) {
                isFiltered = false;
                break;
            }
        }
        return criteriaActive.length === 0 ? true : isFiltered;
    }
}