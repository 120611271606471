import ScrollManager from "@web/vpt-assets/assets/js/utilities/scrollManager";

export default class GroupListPrinter {
    constructor(groupElements) {
        this.scrollManager = new ScrollManager();
        this.groupElements = groupElements;
        this.groupElementsById = [];
        this.bannerElementsById = [];
        this._initializeView();
        this.groupsContainer = document.querySelector('[data-container="groups-sorted"]');
        this.noSortableElements = document.querySelectorAll('[data-not-sortable]');

    }

    _initializeView() {
        this.groupElements.forEach((groupElement) => {
            this.groupElementsById[groupElement.dataset.id] = groupElement;
        });

        let bannerElements = document.querySelectorAll('[data-banner]');

        bannerElements.forEach((bannerElement) => {
            this.bannerElementsById[bannerElement.dataset.position] = bannerElement;
        });
    }

    updateGroupsDistance(groups) {
        groups.forEach((group) => {
            let groupElement = this.groupElementsById[group.id];
            if (!groupElement || !group.distance) {
                return;
            }
            groupElement.querySelector('[data-km-distance]').innerHTML = group.distance + ' km';
            groupElement.querySelector('[data-container="distance"]').classList.remove('hidden');
        });
    }

    printGroups(groupsIds) {
        this.groupsContainer.innerHTML = '';
        groupsIds.forEach((groupId, index) => {
            if (this.bannerElementsById[index]) {
                this.groupsContainer.appendChild(this.bannerElementsById[index]);
            }
            if (this.groupElementsById[groupId]) {
                this.groupsContainer.appendChild(this.groupElementsById[groupId]);
            }
        });

        document.querySelectorAll('[data-category]').forEach((container) => {
            container.remove()
        });

        document.querySelectorAll('[data-not-sortable]').forEach((container) => {
            container.remove()
        });
        this.scrollManager.scrollTo('#central_content', () => {
        }, 0, true, 100);
    }
}