import GroupValueCalculator from "./groupValueCalculator";
import UserLocationManager from "./userLocationManager";

export default class GroupListSorter {
    constructor(onSortFunction, onUpdateGroupsDistance) {
        this.groupValueCalculator = new GroupValueCalculator();
        this.userLocationManager = new UserLocationManager(onUpdateGroupsDistance);
        this.onSortFunction = onSortFunction;
        this.selectedSortMethod = null;
        this.selectedSortType = null;
        this.userLocationModalId = '#user_location';
    }

    initializeSorter() {
        document.querySelectorAll('[data-action="sort"]').forEach((element) => {
            element.addEventListener('click', (filterElement) => {
                let sortMethod = filterElement.currentTarget.dataset.method;
                let sortType = filterElement.currentTarget.dataset.type;
                if (this.setSortMethod(sortMethod, sortType)) {
                    this.onSortFunction();
                }
            });
        });

        let getParams = new URLSearchParams(window.location.search);
        let method = getParams.get(GroupListSorter.METHOD_PARAM);
        let type = getParams.get(GroupListSorter.TYPE_PARAM);

        if (!method || !this.checkSortMethod(method) || !type || !this.checkSortType(type)) {
            return false;
        }

        this.setSortMethod(method, type);

        return true;
    }

    checkSortMethod(method) {
        return GroupListSorter.METHODS.includes(method);
    }

    checkSortType(type) {
        return GroupListSorter.TYPES.includes(type);
    }

    setSortMethod(sortMethod, sortType) {
        if (!this.checkSortMethod(sortMethod) || !this.checkSortType(sortType)) {
            return false;
        }
        if (sortMethod !== GroupListSorter.METHOD_DISTANCE && sortMethod == this.selectedSortMethod && sortType == this.selectedSortType) {
            return false;
        }
        this.selectedSortMethod = sortMethod;
        this.selectedSortType = sortType;
        return true;
    }

    sort(groups) {
        if (!this.selectedSortMethod || !this.selectedSortType) {
            return groups;
        }

        groups.sort((groupA, groupB) => {
            return this.compare(groupA, groupB);
        });

        return groups;
    }

    compare(groupA, groupB) {
        let groupAValue, groupBValue;

        switch (this.selectedSortMethod) {
            case GroupListSorter.METHOD_PRICE:
                groupAValue = this.groupValueCalculator.getPriceValue(groupA);
                groupBValue = this.groupValueCalculator.getPriceValue(groupB);
                break;
            case GroupListSorter.METHOD_RATING:
                groupAValue = this.groupValueCalculator.getRateValue(groupA);
                groupBValue = this.groupValueCalculator.getRateValue(groupB);
                break;
            case GroupListSorter.METHOD_DISTANCE:
                groupAValue = this.groupValueCalculator.getDistanceValue(groupA, this.userLocationManager.getUserLocation());
                groupBValue = this.groupValueCalculator.getDistanceValue(groupB, this.userLocationManager.getUserLocation());
                break;

            default:
                groupAValue = this.groupValueCalculator.getTimeValue(groupA);
                groupBValue = this.groupValueCalculator.getTimeValue(groupB);
                break;
        }

        if (this.selectedSortType === GroupListSorter.TYPE_DESCENDING) {
            return groupBValue - groupAValue;
        } else {
            return groupAValue - groupBValue;
        }
    }

    updateSorterView() {
        this._updateGetParams();
        let method, type, input;
        document.querySelectorAll('.sorter-item').forEach((element) => {
            method = element.dataset.method;
            type = element.dataset.type;
            if (method == this.selectedSortMethod && type == this.selectedSortType) {
                element.classList.add('active');
                input = element.querySelector('input');
                if (input) {
                    input.checked = true;
                }

            } else {
                element.classList.remove('active');
            }
        });

        document.querySelectorAll('[data-sort-category]').forEach((element) => {
            method = element.dataset.sortCategory;
            if (method == this.selectedSortMethod) {
                element.classList.add('active');
            } else {
                element.classList.remove('active');
            }
        });
    }

    _updateGetParams() {
        let getParams = new URLSearchParams(window.location.search);
        let parametersModified = true;

        if (this.selectedSortMethod && this.selectedSortType) {
            getParams.set(GroupListSorter.METHOD_PARAM, this.selectedSortMethod);
            getParams.set(GroupListSorter.TYPE_PARAM, this.selectedSortType);
            parametersModified = true;
        } else if (getParams.has(GroupListSorter.METHOD_PARAM) || getParams.has(GroupListSorter.TYPE_PARAM)) {
            getParams.delete(GroupListSorter.METHOD_PARAM);
            getParams.delete(GroupListSorter.TYPE_PARAM);
            parametersModified = true;
        }
        if (parametersModified) {
            window.history.replaceState({}, '', '?' + getParams.toString());
        }
    }
}


GroupListSorter.METHOD_PRICE = 'price';
GroupListSorter.METHOD_RATING = 'rate';
GroupListSorter.METHOD_TIME = 'time';
GroupListSorter.METHOD_DISTANCE = 'distance';

GroupListSorter.METHODS = [
    GroupListSorter.METHOD_PRICE,
    GroupListSorter.METHOD_RATING,
    GroupListSorter.METHOD_TIME,
    GroupListSorter.METHOD_DISTANCE
];

GroupListSorter.TYPE_ASCENDING = 'asc';
GroupListSorter.TYPE_DESCENDING = 'desc';

GroupListSorter.TYPES = [
    GroupListSorter.TYPE_ASCENDING,
    GroupListSorter.TYPE_DESCENDING,
]


GroupListSorter.METHOD_PARAM = 'sorter';
GroupListSorter.TYPE_PARAM = 'sorterOrder';