import Group from "./group";

export default class GroupList {
    constructor(groupElements) {
        this.groupList = [];
        this._generateGroupList(groupElements);
    }

    _generateGroupList(groupElements) {
        groupElements.forEach((element) => {
            let group = new Group(
                element.dataset.id,
                element.dataset.latitude,
                element.dataset.longitude,
                element.dataset.price,
                element.dataset.filterPrice,
                element.dataset.filterAccommodationcategory,
                element.dataset.filterRating,
                element.dataset.time,
                element.dataset.tags.split(';').map(elem=> parseInt(elem)),
                element.dataset.filterAllowpets ?? false,
            );
            this.groupList.push(group)
        });
    }

    getGroupById(groupId) {
        for (let group of this.groupList) {
            if (group.id == groupId) {
                return hotel;
            }
        }
    }

    getList() {
        return this.groupList;
    }
}