import CriteriaList from "./criteria/criteriaList";

export default class FilterPrinter {
    constructor(criteriaList, onFilterFunction) {
        this._criteriaList = criteriaList;
        this.onFilterFunction = onFilterFunction;
        this.eventDelegation();
    }

    eventDelegation() {
        document.querySelector('[data-open="filters"]').addEventListener('click', (element) => {
            document.querySelector('[data-container="filters"]').classList.add('show');
            document.querySelector('body').classList.add('filters-opened');

        });

        document.querySelectorAll('[data-close="filters"]').forEach((element) => {
            element.addEventListener('click', (element) => {
                document.querySelector('[data-container="filters"]').classList.remove('show');
                document.querySelector('body').classList.remove('filters-opened');
            });
        });

        document.querySelectorAll('[data-action="reset-filters"]').forEach((element) => {
            element.addEventListener('click', () => {
                this._resetFilters();
            });
        });
    }

    updateFilterView(filteredGroups, groups) {
        this._updateFilterCategories();
        this._updateCountTexts(filteredGroups, groups);
        this._updateFiltersApplied();
        this._updateGetParams();
    }

    initializeFilters() {
        let getParams = new URLSearchParams(window.location.search);
        let filtersApplied = false;

        CriteriaList.criteriaTypes.forEach((criteriaType) => {
            if (!getParams.has(criteriaType)) {
                return;
            }
            let valueArray = getParams.get(criteriaType);
            valueArray.split(',').forEach((value) => {
                let criteria = this._criteriaList.getCriteria(criteriaType, value)
                if (!criteria) {
                    return;
                }
                let element = this._getCriteriaElement(criteria);
                criteria.checked = true;
                element.checked = true;
                filtersApplied = true;
            });
        });

        return filtersApplied;
    }

    _updateGetParams() {
        let getParams = new URLSearchParams(window.location.search);
        let parametersModified = false;
        CriteriaList.criteriaTypes.forEach((criteriaType) => {
            let criteriaCheckedList = this._criteriaList.getCriteriaChecked(criteriaType);
            let criteriaIdList = criteriaCheckedList.map((criteria) => {
                return criteria.valueA;
            }).toString();

            if (getParams.has(criteriaType) && !criteriaIdList) {
                getParams.delete(criteriaType);
                parametersModified = true;
            }
            if (criteriaIdList) {
                getParams.set(criteriaType, criteriaIdList);
                parametersModified = true;
            }
        });
        if (parametersModified) {
            window.history.replaceState({}, '', '?' + getParams.toString());
        }
    }

    setCriteriaVisibility(criteria) {
        let element = this._getCriteriaElement(criteria).closest('[data-hide="checkbox"]');
        element.querySelector('[data-field="groups-counter"]').innerHTML = criteria.numItems;

        if (criteria.numItems || criteria.checked) {
            element.style.display = '';
        } else {
            element.style.display = 'none';
        }
    }

    _updateFilterCategories() {
        document.querySelectorAll('[data-container-filter-type]').forEach((element) => {
            let type = element.dataset.containerFilterType;
            if (this._criteriaList.hasItemsCriteriaType(type)) {
                element.style.display = '';
            } else {
                element.style.display = 'none';
            }
        });
    }

    _updateFiltersApplied() {
        let filterAppliedTemplate, filterApplied, filterAppliedList;

        document.querySelectorAll('[data-entity="applied-filter"]').forEach((element) => {
            filterAppliedList = element.querySelector('[data-filter-applied="list"]');
            filterAppliedTemplate = element.querySelector('[data-filter-applied="template"]');
            filterAppliedList.innerHTML = '';

            if (this._criteriaList.hasCriteriaChecked()) {
                element.style.display = '';
                this._criteriaList.getCriteriaChecked().forEach((criteria) => {
                    filterApplied = filterAppliedTemplate.cloneNode(true)
                    filterApplied.querySelector('[data-filter-name]').innerHTML = criteria.text;
                    filterApplied.style.display = '';
                    filterApplied.addEventListener('click', () => {
                        const e = new Event("change");
                        const element = this._getCriteriaElement(criteria);
                        element.checked = false;
                        element.dispatchEvent(e);
                    });
                    filterAppliedList.appendChild(filterApplied);
                });
            } else {
                element.style.display = 'none';
            }
        });
    }

    _getCriteriaElement(criteria) {
        return document.querySelector('[data-action="filter"][data-filter-type="' + criteria.name + '"][data-valuea="' + criteria.valueA + '"]');
    }

    _updateCountTexts(filteredGroups, groups) {
        let text, element;
        let filterSummary = document.querySelector('[data-container="filter-title"][data-template-text]');

        if (filteredGroups.length === groups.length) {
            element = document.querySelector('[data-container="filter-button"][data-template-text-no-results]');
            text = element.dataset.templateTextNoResults;
            element.innerHTML = text;
            filterSummary.style.display = 'none';
        } else {
            let text = filterSummary.dataset.templateText;
            filterSummary.style.display = '';
            text = text.replace('%currentCount%', filteredGroups.length);
            text = text.replace('%totalCount%', groups.length);
            filterSummary.innerHTML = text;

            element = document.querySelector('[data-container="filter-button"][data-template-text]');
            text = element.dataset.templateText;
            text = text.replace('%currentCount%', filteredGroups.length);
            element.innerHTML = text;
        }
    }


    _resetFilters() {
        this._criteriaList.cleanCriteria();

        document.querySelectorAll('[data-action="filter"]').forEach((element) => {
            element.checked = false;
        });

        this.onFilterFunction();
    }
}